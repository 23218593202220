<template>
	<section>
		<b-row class="mb-2">
		    <b-col lg="12">
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">	
			            	<validation-observer
						        ref="form_rel"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px; margin-top: 10px">
				                	<b-row class="align-center">
							            <b-col lg-12>
							            	<div class="text-right mb-2">
							                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAddService" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

							                    <template v-if="userData">
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAddServer">
								                        Save
								                    </b-button>
								                </template>
								                <template v-else>
								                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Save
								                    </b-button>
								                </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;"> 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="SVID" label-cols-lg="4" class="input-group-label">
				                                    <b-form-input
				                                      placeholder="Service ID"
				                                      readonly
				                                    ></b-form-input>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0"> 
					                            <b-form-group label="Date Created" label-cols-lg="4" class="input-group-label">
				                                     <b-form-input
				                                      	placeholder="dd/mm/yy"
				                                      	readonly
				                                    ></b-form-input>
					                            </b-form-group>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Service Name" label-cols-lg="2" class="star-required input-group-label">     
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Service Name"
					                                  vid="name"
					                                  rules="required"                
					                                >                       
					                                    <b-form-input
					                                    	v-model= "fieldService.name"
					                                      	placeholder="Name"
					                                      	:formatter="length100"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">   
				                          		<b-form-group label="Acronym" label-cols-lg="4" class="star-required input-group-label">            
				                          			<validation-provider
					                                  #default="{ errors }"
					                                  name="Acronym"
					                                  vid="acronym"
					                                  rules="required"                
					                                >    
							                            <b-form-input
							                            	v-model= "fieldService.acronym"
					                                      	placeholder="Service Acronym"
					                                      	:formatter="length50"
						                                >
						                                </b-form-input>
						                                <small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
				                                </b-form-group>
				                          	</b-col> 	 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
					                                  #default="{ errors }"
					                                  name="Status"
					                                  vid="status"
					                                  rules="required"                      
					                                > 
						                                <v-select
						                                	v-model= "fieldService.status"
						                                    label="title"
						                                    :options="selectStatus"
						                                    placeholder="Select status"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>                     
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../model/globalAdmin';
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "administrations_service_add": "" },   
			    selectStatus: [
			    	{title: 'Active', value: 1},
			    	{title: 'Inactive', value: 0},
			    ],
				permission: [],
			    fieldService: {
			    	name: null,
			    	acronym: null,
			    	status: null,
			    },			   
            }
		},
		created() {
			this.permission = this.checkPermission(this.userData,"Administration");
		},	
		methods: {
			saveAddServer(){				
				this.$refs.form_rel.validate().then(success => {

					if (success){

						let fields = {
		                  	name: this.fieldService.name,
		                  	acronym: this.fieldService.acronym,         
		                  	status: this.fieldService.status.value,       
		                }
		                // console.log('fields: ', fields)

						this.$store
						.dispatch('auth/addServiceSetup', fields)
						.then(response => {
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: this.fieldService.name + ' Added!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
			                this.$router.push({ name: 'staff_administrations', query: { tab: 2 } })
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: error.response.data.message,
			                          variant: 'danger',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
						})
					}
					else {
						this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
					}
				})
			},
			cancelAddService(){
				this.$router.push({ name: 'staff_administrations', query: { tab: 2 } })
			},
			length100(e){
				return String(e).substring(0,100)
			},
			length50(e){
				return String(e).substring(0,50)
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	@media only screen and (min-width: 1401px){
		.options_list .vs__selected-options > input, .icon-search-wrapper input{
			height: 50px;
		}
	}
	@media only screen and (min-width: 1200px){
		.options_list .vs__selected-options > input, .icon-search-wrapper input{
			height: 40px;
		}
	}	
</style>