var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"lg":"12"}},[_c('Breadcrumb',{attrs:{"labels":_vm.labels}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"relative"},[_c('b-tabs',{staticClass:"caption-add table-custom",attrs:{"card":""}},[_c('b-tab',{staticClass:"overview-tab",attrs:{"title":"Basic Info","active":""}},[_c('validation-observer',{ref:"form_rel",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"profile-frm mb-20",staticStyle:{"border-radius":"5px","margin-top":"10px"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"align-center"},[_c('b-col',{attrs:{"lg-12":""}},[_c('div',{staticClass:"text-right mb-2"},[_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)","margin-right":"10px"},attrs:{"type":"button","variant":"basic"},on:{"click":_vm.cancelAddService}},[_vm._v("Cancel")]),(_vm.userData)?[_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.saveAddServer}},[_vm._v(" Save ")])]:[_c('b-button',{staticClass:"btn-df size-18 black btn-disabled-gray",attrs:{"type":"button","variant":"primary","disabled":""}},[_vm._v(" Save ")])]],2)])],1),_c('div',{staticClass:"pb-2 mb-10",staticStyle:{"border-top":"1px solid #000000B2"}}),_c('div',{staticClass:"frm-basic size-16 black-child d-block"},[_c('b-row',{staticStyle:{"width":"100%","margin":"0"}},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"SVID","label-cols-lg":"4"}},[_c('b-form-input',{attrs:{"placeholder":"Service ID","readonly":""}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Date Created","label-cols-lg":"4"}},[_c('b-form-input',{attrs:{"placeholder":"dd/mm/yy","readonly":""}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Service Name","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Service Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Name","formatter":_vm.length100},model:{value:(_vm.fieldService.name),callback:function ($$v) {_vm.$set(_vm.fieldService, "name", $$v)},expression:"fieldService.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Acronym","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Acronym","vid":"acronym","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Service Acronym","formatter":_vm.length50},model:{value:(_vm.fieldService.acronym),callback:function ($$v) {_vm.$set(_vm.fieldService, "acronym", $$v)},expression:"fieldService.acronym"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Status","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.selectStatus,"placeholder":"Select status"},model:{value:(_vm.fieldService.status),callback:function ($$v) {_vm.$set(_vm.fieldService, "status", $$v)},expression:"fieldService.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }